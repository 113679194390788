import List from "Components/List";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FaPen, FaTrash, FaRegFileLines} from "react-icons/fa6";
import Clickable from "Components/BaseComponents/Clickable";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import Gap from "../../../Components/BaseComponents/Gap";
import useToasts from "../../../Hooks/UseToasts";
import {useDeleteBlock} from "../Services/BlockApi";
import {BlockDto} from "../Services/BlockDto";
import {ContactApi, CustomerApi} from "../../Customers";
import {useBlockTypes} from "../Services/BlockTypeApi";
import {useBlockSubscriptions} from "../Services/BlockSubscriptionApi";

interface Props {
    data?: BlockDto[];
    isLoading: boolean;
}
const BlocksList = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToasts();

    const { data: customers, isLoading: isLoadingCustomers } = CustomerApi.useCustomers();
    const { data: contacts, isLoading: isLoadingContacts } = ContactApi.useContacts();
    const { data: blockTypes, isLoading: isLoadingBlockTypes } = useBlockTypes()
    const { data: blockSubscriptions, isLoading: isLoadingSubscriptions } = useBlockSubscriptions()
    
    const { mutateAsync: deleteBlockMutation, isLoading: isDeleting } = useDeleteBlock();
    
    const deleteBlock = async (block: BlockDto) => {
        await deleteBlockMutation(block);
        
        showSuccessToast(t('blockDeleted'));
    }

    return (
        <List<BlockDto> data={props.data} loading={props.isLoading || isLoadingContacts || isLoadingSubscriptions || isLoadingBlockTypes || isLoadingCustomers} columns={[
            {
                title: t('serialNumber'),
                textRender: (record) => record.serialNumber
            },
            {
                title: t('customer'),
                textRender: (record) => customers?.items.filter(x => record.customers?.includes(x.id)).flatMap(x => [x.name, x.phone]).flatMap(x => x).join(', ') ?? ""
            },
            {
                title: t('contact'),
                textRender: (record) => contacts?.items.filter(x => record.contacts?.includes(x.id))?.map(x => x.name).join(', ') ?? ""
            },
            {
                title: t('address'),
                textRender: (record) => `${record.address} ${record.city} ${record.zipCode}`
            },
            {
                title: t('blockType'),
                textRender: (record) => blockTypes?.items.find(x => x.id === record.blockTypeId)?.name ?? ""
            },
            {
                title: t('blockSubscription'),
                textRender: (record) => blockSubscriptions?.items.find(x => x.id === record.blockSubscriptionId)?.name ?? ""
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                        <Gap>
                            <Clickable onClick={() => navigate(`/blocks/services/create/block/${record.id}`)}><FaRegFileLines />{t('createBlockService')}</Clickable>
                            <Clickable onClick={() => navigate(`/blocks/edit/${record.id}`)}><FaPen />{t('edit')}</Clickable>
                            <ConfirmationDialog loading={isDeleting} onConfirm={() => deleteBlock(record)} buttonType={'danger'} title={t('areYouSure')}>
                                <Clickable><FaTrash />{t('delete')}</Clickable>
                            </ConfirmationDialog>
                        </Gap>
                    ) 
                
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )

}

export default BlocksList